import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'

const Travelers = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">extremophiles like to argue</span>
        </div>
      </div>
    </section>
    <section className="section song">
      <div className="container song is-fluid">
        <div className="columns">
          <div className="column is-half art travelers"></div>
          <div className="column is-half lyrics">
            <p>
              Far from the star of our home world we rest in this ice world we wait for new land
            </p>

            <p>
              &nbsp;&nbsp;Blown into space by a terrible blast. A horrible fate is awaiting us here in the void
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;Deep space is quiet and restful and cold&hellip; hardened ’gainst radiation we sleep<br/><br/>
            </p>

            <p>
              Something comes slowly and makes itself known&hellip; now we're on track to a new star
            </p>

            <p>
              &nbsp;&nbsp;This monster is pulling us faster we move- away from our neighbors far far away
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;A new journey finds us speed fast on our way - the light getting brighter- the cold fades<br/><br/>
            </p>

            <p>
              Here comes a source of new light and heat - a golden beacon - our deliverance!
            </p>

            <p>
              &nbsp;&nbsp;We rush to this nightmare, our doom to be scorched - blackened - cold -  and dead!
            </p>

            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;Maybe a new place to live, eat, and sleep&hellip; on a nurturing world?<br/><br/>
            </p>

            <p>
              Only a few must survive&hellip;
            </p>

            <p class="is-size-5">
              &nbsp;&nbsp;Bringing the secrets of life&hellip;
            </p>

            <p class="is-size-4">
              &nbsp;&nbsp;&nbsp;&nbsp;One world grows larger&hellip;
            </p>

          </div>

        </div>
      </div>
  </section>
  </Layout>
)

export default Travelers
